<template>
  <div v-if="isLoaded" class="page-actions">
    <div class="wrapper max-content">
      <div class="title">Produkt</div>
      <template v-if="hasRole('super-admin') || hasRole('admin')">
        <span v-if="drugStatus?.migration === 'PENDING'" class="update-notification">
          Update available
          <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
        </span>
      </template>
      <template
        v-if="
          hasRole('super-admin') ||
          hasRole('admin') ||
          isOwner(drug.producer.code)
        "
      >
        <span class="link" @click="editDrug">Edit product</span>
        <span class="edit" @click="editDrug">
          <font-awesome-icon :icon="['fal', 'edit']" />
        </span>
      </template>
      <span class="anchor" v-if="smpcSections.length" @click="scrollAnchor"
        >Gå til produktresumé</span
      >
    </div>
  </div>
  <ProductNotFound v-if="loadingError && loadingError.status === 404" />
  <div class="loader" v-if="!isLoaded && !loadingError.status">
    <pre-loader />
  </div>
  <div v-if="isLoaded && drug.id" class="max-content">
    <div class="container">
      <div class="sidebar">
        <div class="drug-title">{{ drug.title }}</div>
        <div class="input-row subtitle">
          {{ drug.details.dosage_form.text }}
          <template v-if="drug.details.strength.text">
            ({{ drug.details.strength.text }})
          </template>
        </div>
        <div
          v-if="drug.details.images.length && drug.promoted"
          class="input-row"
        >
          <div class="media-section">
            <template v-for="(n, i) in drug.details.images.slice(0, 3)" :key="n">
              <div class="media-block" @click="openGallery(i)">
                <img
                  v-if="n.path !== 'link'"
                  :src="`${baseApiUrl}/api/media/${n.path}/preset/thumbnail`"
                  onerror="this.onerror=null; this.remove();"
                  alt="drug Images"
                />
                <template v-if="n.path === 'link' && n.link">
                  <img src="@/assets/images/play_small.png" alt="play" class="play" />
                  <VideoPlayer :url="n.link.uri" thumbnail />
                </template>
              </div>
            </template>
            <div v-if="drug.details.images.length > 3" class="counter">
              + {{ drug.details.images.length - 3 }}
            </div>
          </div>
        </div>
        <div
          class="input-row"
          v-if="
            categoriesByCode('activeingredient') &&
            categoriesByCode('activeingredient').length
          "
        >
          <h2>Aktive substanser</h2>
          <template
            v-for="(activeingredient, i) in categoriesByCode('activeingredient')"
            :key="`activeingredient-${i}`"
          >
            <div
              class="chip fill"
              :title="activeingredient.category_title"
              @click="filterDrugsByCategories(activeingredient.category_id)"
            >
              <div class="chip-content">
                <div class="title">{{ activeingredient.category_title }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class="input-row" v-if="drug.packages && drug.packages.length">
          <div class="packages">
            <div class="labels">
              <h2 class="size">Pakning</h2>
              <h2 class="code">NVR</h2>
              <h2>Forbrugerpris inkl. moms</h2>
            </div>
            <template v-for="(item, i) in drug.packages" :key="`package-${i}`">
              <div class="package" :class="{ discontinue: item.details.withdrawal_date }">
                <div class="size">{{ item.details.package_size.text }}</div>
                <div class="code">{{ item.code }}</div>
                <div>
                  <span>{{
                    formatPrice(
                      item.details.package_prices.consumer_price.amount
                    )
                  }}</span>
                  <font-awesome-icon
                    v-if="item.details.withdrawal_date"
                    :title="
                      onHoverMessage(
                        item.details.withdrawal_date
                      )
                    "
                    class="info-icon"
                    :icon="['fas', 'info-circle']"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="input-row">
          <h2>Dyr</h2>
          <template
            v-for="(primary, i) in categoriesByCode('primary')"
            :key="`primary-${i}`"
          >
            <div
              class="chip large"
              @click="filterDrugsByCategories(primary.category_id)"
            >
              <div class="chip-content" :title="primary.category_title">
                <img
                  class="icon"
                  :src="primaryCategoryIcon(primary.category_id)"
                  onerror="this.onerror=null; this.remove();"
                />
                <div class="title">{{ primary.category_title }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class="input-row" v-if="drug.details.withdrawal_period">
          <h2>Tilbageholdelsestid</h2>
          <div class="text" v-html="formatText(drug.details.withdrawal_period)"></div>
        </div>
        <!-- <div class="input-row" v-if="drug.details.strength.text">
          <h2>Dosering</h2>
          {{ drug.details.strength.text }}
        </div> -->
        <div class="input-row" v-if="packageDeliveryRegulationCodes.length">
          <h2>Udleveringsbestemmelse</h2>
          <div class="text">{{ packageDeliveryRegulationCodes }}</div>
        </div>
        <div class="input-row">
          <h2>ATC-kode</h2>
          <div class="link" @click="openATCModal(drug.atc_category.code)">
            {{ drug.atc_category.code }}
          </div>
        </div>
        <div class="input-row">
          <h2>Firma</h2>
          <router-link
            class="link"
            :to="`/company/${ drug.producer.slug || drug.producer.id }`"
          >
            {{ drug.producer.title }}
          </router-link>
        </div>
        <div v-if="drug.details.files.length" class="file input-row">
          <h2>SPC</h2>
          <div class="files">
            <template v-for="(file, i) in drug.details.files" :key="`file-${i}`">
              <div class="file">
                <div class="title">{{ removeFileExtension(file.title) }}</div>
                <span
                  ><font-awesome-icon
                    :icon="['far', 'eye']"
                    @click="previewFile(file.path)"
                /></span>
                <span
                  ><font-awesome-icon
                    :icon="['far', 'download']"
                    @click="downloadFile(file.path, file.title)"
                /></span>
              </div>
            </template>
          </div>
        </div>
        <div class="input-row" v-if="categoriesByCode('tag').length">
          <h2>Administrationsveje</h2>
          <template
            v-for="(tag, i) in categoriesByCode('tag')"
            :key="`tag-${i}`"
          >
            <div
              class="chip"
              @click="filterDrugsByCategories(tag.category_id)"
            >
              <div class="chip-content">
                <div class="title">{{ tag.category_title }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class="input-row" v-if="categoriesByCode('usage').length">
          <h2>Anvendelsesområde</h2>
          <template
            v-for="(usage, i) in categoriesByCode('usage')"
            :key="`usage-${i}`"
          >
            <div
              class="chip"
              @click="filterDrugsByCategories(usage.category_id)"
            >
              <div class="chip-content">
                <div class="title">{{ usage.category_title }}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="content">
        <template v-if="drug.promoted">
          <h2 v-if="smpcSections.length">Produktresumé</h2>
          <template v-for="(smpc, i) in smpcSections" :key="`smpc-${i}`">
            <ui-expansion-panel v-model="smpc.open">
              <template v-slot:header>{{ smpc.title }}</template>
              <template v-slot:content>
                <div v-html="smpc.value"></div>
              </template>
            </ui-expansion-panel>
          </template>
        </template>
        <template v-else>
          <div class="unpaid">
            <img src="@/assets/images/logo_red.png" alt="Logo" class="logo" />
            <div class="text">Der er begrænset information på dette produkt.</div>
          </div>
        </template>
      </div>
      <Gallery
        v-if="drug.details.images.length"
        :items="drug.details.images"
        :watermarks="true"
        ref="gallery"
      />
    </div>
  </div>
  <ATCRegister ref="atc-register" />
  <VueMeta v-if="isLoaded" :meta-info="metaInfo" />
</template>

<script>
import { defineComponent } from 'vue';
import ProductNotFound from '@/components/ui/ProductNotFound.vue';
import VueMeta from '@/components/meta/VueMeta.vue';
import moment from 'moment';
import MedicineService from '@/services/MedicineService';
import axios from 'axios';
import fileDownload from 'js-file-download';
import InventoryService from '@/services/InventoryService';
import CategoryService from '@/services/CategoryService';
import SmPCService from '@/services/SmPCService';
import RolePermissions from '@/mixins/RolePermissions';
import PriceFormatter from '@/mixins/PriceFormatter';
import VideoPlayer from '@/components/plyr/VideoPlayer.vue';
import Gallery from '@/components/modals/Gallery.vue';
import UiExpansionPanel from '@/components/form/UiExpansionPanel.vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import ATCRegister from '@/components/modals/ATCRegister.vue';

export default defineComponent({
  name: 'Product',
  mixins: [RolePermissions, PriceFormatter],
  components: {
    ProductNotFound,
    VueMeta,
    VideoPlayer,
    Gallery,
    UiExpansionPanel,
    PreLoader,
    ATCRegister,
  },
  data: () => ({
    loadingError: {},
    isLoaded: false,
    primary: [],
    drug: {},
    drugStatus: {},
    smpcTemplateVersion: '',
    smpcSections: [],
    packageDeliveryRegulationCodes: [],
  }),
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    promotedSmpcKeys() {
      return (
        this.smpcTemplateVersion === 'v9.0'
          ? process.env.VUE_APP_PROMOTED_SMPC_V9_0_KEYS
          : process.env.VUE_APP_PROMOTED_SMPC_V8_2_KEYS
      ).split(', ');
    },
    drugId() {
      return this.$route.params.drugId;
    },
    metaTitle() {
      const dosage = this.drug.details?.dosage_form?.text
        ? ` - ${this.drug.details?.dosage_form?.text}`
        : '';
      const strength = this.drug.details?.strength?.text
        ? ` - ${this.drug.details?.strength?.text}`
        : '';

      return ''.concat(
        this.drug.title,
        dosage,
        strength,
        ` - ${process.env.VUE_APP_TITLE}`,
      );
    },
    metaDescriptionSmpcKeys() {
      return this.smpcTemplateVersion === 'v9.0'
        ? process.env.VUE_APP_PRODUCT_META_DESCRIPTION_SMPC_V9_0_KEYS
        : process.env.VUE_APP_PRODUCT_META_DESCRIPTION_SMPC_V8_2_KEYS;
    },
    metaDescription() {
      let desc = this.smpcSections.filter((s) => this.metaDescriptionSmpcKeys.includes(s.key));

      desc = desc.map((e) => e.value);
      desc = desc.join('. ');

      desc = desc.replace(/<[^>]+>/g, ' ');
      desc = desc.replace(/2/g, ' ');
      desc = desc.replace(/(\r\n|\n|\r)/gm, ' ');

      return desc || '';
    },
    metaKeywords() {
      const keywords = this.drug.entity_categories
        ?.filter(
          (c) => c.category_type_code === 'primary',
        )
        ?.map((k) => k.category_title)
        ?.join(', ');

      return keywords || '';
    },
    metaHref() {
      return `${window.location.origin}/product/${this.drug.slug}`;
    },
    metaInfo() {
      return {
        title: this.metaTitle,
        description: this.metaDescription,
        link: [{ rel: 'canonical', href: this.metaHref }],
        meta: [{ name: 'keywords', content: this.metaKeywords }],
      };
    },
  },
  methods: {
    async fetchData() {
      try {
        const code = this.drugId.match(/^\d{11}/);
        const drugIdCode = code?.[0] || this.drugId;

        const [
          primary,
          drug,
        ] = await Promise.all([
          CategoryService.primary({ take: 50 }),
          InventoryService.drug(drugIdCode),
        ]);

        if (!drug.published) {
          this.loadingError = { status: 404 };
          return;
        }

        this.primary = primary;

        this.drug = {
          id: drug.id,
          code: drug.code,
          slug: drug.slug,
          title: drug.title,
          promoted: drug.promoted,
          details: {
            dosage_form: {
              active: drug.details.dosage_form.active,
              code: drug.details.dosage_form.code,
              text: drug.details.dosage_form.text,
            },
            strength: drug.details.strength,
            routes: drug.details.routes,
            withdrawal_period: drug?.details?.withdrawal_period,
            images: drug.details.images,
            files: drug.details.files,
            smpc_details: drug?.details?.smpc_details
              ? {
                smpc_id: drug?.details?.smpc_details?.source,
                spmc_name: drug?.details?.smpc_details?.title,
              }
              : {},
            de_registration_date: drug?.details?.de_registration_date,
            quarantine_date: drug?.details?.quarantine_date,
          },
          producer: drug.producer,
          atc_category: {},
          packages: drug.units,
          entity_categories: drug.entity_categories,
        };

        const atc = drug.entity_categories.find((el) => el.category_type_code === 'atc');

        if (atc?.category_id) {
          this.drug.atc_category = await CategoryService.atcShow(atc.category_id);
        }

        if (drug?.details?.smpc_details?.source) {
          try {
            const smpc = await SmPCService.showSmpc(drug.details.smpc_details.source);

            this.smpcTemplateVersion = smpc?.artifact?.template_version;

            const sections = smpc?.smpc_details?.smpc_sections || [];
            const promoted = [];

            this.promotedSmpcKeys.forEach((key) => {
              const section = sections.find((s) => s.key === key);

              if (section) {
                section.open = true;
                promoted.push(section);
              }
            });

            const other = sections.filter((s) => !this.promotedSmpcKeys.includes(s.key));

            this.smpcSections = [...promoted, ...other]
              .map((s) => ({
                title: s.title,
                key: s.key,
                value: s.value,
                open: s.open || false,
              }));
          } catch (error) {
            this.smpcSections = [];
          }
        }

        const packageDeliveryregulations = [];

        drug.units.forEach((unit) => unit?.entity_categories?.forEach((category) => {
          if (category?.category_type_code === 'deliveryregulation') {
            packageDeliveryregulations.push(CategoryService.show(category.category_id));
          }
        }));

        const packageDeliveryregulationResponse = await Promise.all(packageDeliveryregulations);

        this.packageDeliveryRegulationCodes = [
          ...new Set(packageDeliveryregulationResponse.map((el) => el?.code)),
        ]?.join(', ');

        this.drugStatus = await MedicineService.showStatus(this.drug.code);

        this.isLoaded = true;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.loadingError = error.response;
          return;
        }

        if (error?.response?.status === 422 || error?.response?.status === 409) {
          this.$toast.show(error.response.data?.error?.message, 3000);
          return;
        }

        this.$toast.show('Server error', 3000);
      }
    },
    openATCModal(code) {
      this.$refs['atc-register'].openModal(code);
    },
    formatText(val) {
      return val?.replace(/\n/g, '<br />');
    },
    categoriesByCode(code) {
      return this.drug.entity_categories.filter(
        (el) => el?.category_type_code === code,
      );
    },
    primaryCategoryIcon(id) {
      const category = this.primary.find((el) => el.id === id);

      if (!category) return '';

      return `/assets/Icons/Animals/PNG/${category.details.icon}_red.png`;
    },
    removeFileExtension(input) {
      return input.substr(0, input.lastIndexOf('.')) || input;
    },
    previewFile(fileId) {
      window.open(`${this.baseApiUrl}/api/media/show/${fileId}`, '_blank');
    },
    downloadFile(fileId, title) {
      axios({
        url: `${this.baseApiUrl}/api/media/download/${fileId}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        fileDownload(response.data, title);
      });
    },
    editDrug() {
      this.$router.push({ name: 'ProductEdit', params: { drugId: this.drugId } });
    },
    filterDrugsByCategories(id) {
      this.$router.push({
        name: 'Home',
        query: { category_ids: id },
      });
    },
    scrollAnchor() {
      const el = document.querySelector('.content');
      const y = el.getBoundingClientRect().top + window.scrollY;

      window.scroll({
        top: (y - 2),
        behavior: 'smooth',
      });
    },
    formatDate(date) {
      const newDate = moment(date).format('DD[.]MM[.]YY');

      return newDate;
    },
    onHoverMessage(date) {
      return `Udgået ${
        this.formatDate(date)
      }. Lægemidlet kan være helt udgået eller midlertidigt udgået pga. leveringssvigt.`;
    },
    openGallery(index) {
      this.$refs.gallery.open(index);
    },
  },
  watch: {
    drugId: {
      immediate: true,
      handler(val) {
        if (!val) return;

        this.fetchData();
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.loader {
  min-height: calc(100vh - 115px);
  display: flex;
  align-items: center;
  justify-content: center;
}

h2 {
  margin: 0 0 8px 0;
  font-weight: 600;
  font-family: $font-primary;
  font-size: 16px;
  color: #595959;
}

.page-actions {
  width: 100%;
  padding: 40px 0 30px 0;

  .wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .title {
      font-family: $font-primary;
      font-size: 14px;
      color: #707070;
      margin-right: auto;
    }

    .update-notification {
      position: relative;
      margin-right: 45px;
      font-size: 16px;
      text-decoration: underline;
      color: #eb3d47;

      .fa-exclamation-circle {
        position: absolute;
        top: -2px;
        right: -12px;
        font-size: 11px;
      }
    }

    .link {
      font-size: 16px;
      color: #595959;
      cursor: pointer;
    }

    .edit {
      margin: 0 0 0 10px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #eb3d47;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #eb3d47;
      font-size: 18px;
      cursor: pointer;
    }

    .anchor {
      display: none;
      font-size: 14px;
      color: #eb3d47;
      cursor: pointer;
    }
  }
}

.container {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 0 0 200px;
  font-family: $font-primary;

  .sidebar {
    width: 433px;
    padding: 0 100px 0 0;

    .link {
      color: #eb3d47;
      cursor: pointer;
    }

    .input-row {
      margin-bottom: 35px;

      .text {
        color: #595959;
      }
    }

    .drug-title {
      margin-bottom: 5px;
      font-size: 32px;
      font-family: $font-primary;
      font-weight: bold;
      color: #eb3d47;
    }

    .subtitle {
      font-family: $font-primary;
      font-size: 16px;
      color: #595959;
      opacity: 1;
    }

    .packages {
      width: 100%;
      max-width: 400px;
      margin-bottom: 14px;
      font-family: $font-primary;

      .labels {
        display: flex;
        flex-wrap: nowrap;
      }

      .package {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        color: #595959;

        &:not(:last-child) {
          margin: 0 0 8px 0;
        }

        &.discontinue {
          color: #eb3d47 !important;
          text-decoration: line-through;
        }
      }

      .size, .code {
        padding: 0 20px 0 0;
      }

      .size {
        min-width: 100px;
        max-width: 100px;
      }

      .code {
        min-width: 60px;
        max-width: 60px;
      }

      .info-icon {
        margin: 0 0 0 8px;
        color: #eb3d47;
        cursor: pointer;
      }
    }

    .files {
      max-width: 400px;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 400;

      .file {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        min-height: 40px;
        box-shadow: 0 0 45px #e2e2e2;
        border-radius: 10px;
        margin: 0 0 20px 0;
        padding: 10px 20px;

        .title {
          margin-right: auto;
          color: #939393;
          word-break: break-word;
        }

        span {
          margin-left: 20px;
          font-size: 16px;
          color: #eb3d47;
          cursor: pointer;
        }
      }
    }

    .media-section {
      margin: 20px -4px 30px;
      display: flex;
      flex-wrap: nowrap;

      .media-block {
        width: 111px;
        height: 77px;
        border: 1px solid #edebeb;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 4px;
      }

      img {
        max-width: 90%;
        max-height: 90%;
      }

      .play {
        position: relative;
        z-index: 10;
      }

      .thumbnail {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.3);
      }

      .counter {
        width: 57px;
        min-width: 57px;
        height: 78px;
        transform: matrix(1, -0.02, 0.02, 1, 0, 0);
        border: 1px solid #edebeb;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 24px;
        font-family: $font-primary;
        color: #eb3d47;
        opacity: 1;
      }
    }

    .chip {
      display: inline-block;
      margin: 0 14px 7px 0;
      border-radius: 25px;
      border: 1px solid #eb3d47;
      cursor: pointer;

      .icon {
        max-width: 25px;
        max-height: 25px;
        margin-right: 10px;
      }

      .chip-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 30px;
        padding: 0 10px;

        .title {
          font-size: 16px;
          font-weight: 600;
          color: #eb3d47;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 420px;
        }
      }

      &.large {
        .chip-content {
          height: 50px;
          padding: 0 20px 0 16px;
        }
      }

      &.fill {
        background: #eb3d47;

        .chip-content {
          .title {
            color: #fff;
          }
        }
      }
    }
  }

  .content {
    width: calc(100% - 533px);

    h2 {
      margin-bottom: 25px;
    }

    .unpaid {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 600px;
      background: #F8F8F8;
      border-radius: 10px;

      img {
        margin: 0 0 20px 0;
      }

      .text {
        font-size: 16px;
        font-weight: 400;
        color: #595959;
      }
    }
  }
}

@media (max-width: 1500px) {
  .container .sidebar {
    padding-right: 15px;
  }

  .container .content {
    width: calc(100% - 458px);
  }
}

@media (max-width: 1024px) {
  .page-actions {
    padding: 20px 0 20px;

    .wrapper {
      .update-notification,
      .link,
      .edit {
        display: none;
      }

      .anchor {
        display: inline-block;
      }
    }
  }

  .container {
    flex-wrap: wrap;
    padding: 0 0 60px;

    .sidebar {
      min-width: 100%;
      padding: 0;

      .drug-title {
        font-size: 24px;
      }
    }

    .content {
      width: 100%;

      .unpaid {
        height: 250px;
      }
    }
  }
}
</style>
